

import { mapGetters, mapActions } from 'vuex'
import PDEAccordion from '@/components/pde/PDEAccordion.vue'
import { AvailableToWorkToggle } from '@/components/account/Settings'
import NewJobCard from './NewJobCard.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'
import SpecialtyFilter from './SpecialtyFilter.vue'
import MessageJobCard from './MessageJobCard.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEBadge from '@/components/pde/PDEBadge.vue'
import SortByFilter from './SortByFilter.vue'
import LocationFilter from './LocationFilter.vue'
import UITextfield from '@/components/ui/forms/UITextfield/UITextfield.vue'
import PDEButton from '@/components/pde/PDEButton.vue'

export default {
  name: 'NewJobs',
  components: {
    AvailableToWorkToggle,
    LocationFilter,
    MessageJobCard,
    NewJobCard,
    PDEAccordion,
    PDEButton,
    PDESkeleton,
    SpecialtyFilter,
    PDEHeading,
    PDEBadge,
    SortByFilter,
    UITextfield,
  },

  data() {
    return {
      searchValue: '',
      selectedSpecialty: '',
      setSpecialtyFocused: false,
      isSaving: false,
    }
  },

  mounted() {
    this.sendViewEvent({
      view: 'aaTrackTestExperience',
      detail: {
        'aa-testName': 'chsDisplayPay',
        'aa-testVariation': this.$featureFlags.chsDisplayPay,
      },
    })
  },

  computed: {
    ...mapGetters('jobs', ['newJobsInitialized', 'allJobRecommendations', 'isJobPageLoading', 'selectedSpecialties', 'ignoreNewJobFilters', 'newJobsCount', 'isLastPage']),
    ...mapGetters('account', [
      'availableToWork',
      'division',
      'isPartialUser',
      'primarySpecialty',
      'providerRep',
      'specialtyDetails',
      'isFullUser',
    ]),
    ...mapGetters('identity', ['specialtiesList']),

    flattenedSpecialties() {
      if (Object.keys(this.specialtiesList).length === 0) return []
      const specialties = []

      Object.keys(this.specialtiesList).forEach(key => {
        const profession = this.specialtiesList[key]
        if (Array.isArray(profession)) {
          profession.forEach(specialty => {
            specialties.push(specialty)
          })
        }
      })

      return specialties
    },

    filteredSpecialties() {
      return this.flattenedSpecialties
        .filter(specialty => specialty.name.toLowerCase().includes(this.searchValue.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    },

    noJobsMessage() {
      return this.availableToWork
        ? `We are hard at work looking for the best opportunities for you. Please reach out to ${this.repName} if you need immediate assistance.`
        : `We're unable to display any new jobs for you at the moment. Check in with ${this.repName} via text or email. Or you can turn on 'Available to Work'.`
    },

    noJobsTitle() {
      if (!this.availableToWork) {
        return 'Time to check in with your rep...'
      }
      return 'We\'re on it!'
    },

    repEmail() {
      if (this.isPartialUser) return this.$t(`myteams.jobnames.divisions.${this.division}.defaultEmail`)
      if (!this.providerRep.email || this.providerRep.email === 'unassignedfox@mychg.com.invalid') {
        return this.$t(`myteams.jobnames.divisions.${this.providerRep.division}.defaultEmail`)
      }

      return this.providerRep.email
    },

    repPhone() {
      if (this.isPartialUser) {
        return {
          link: this.$t(`account.contact.${this.division}.questions.link`),
          text: this.$t(`account.contact.${this.division}.questions.text`),
        }
      }

      return {
        link: this.providerRep.phoneRaw ? `tel:${this.providerRep.phoneRaw}` : this.$t(`account.contact.${this.providerRep.division}.questions.link`),
        text: this.providerRep.phone || this.$t(`account.contact.${this.providerRep.division}.questions.text`),
      }
    },

    repName() {
      return this.providerRep.name || 'your rep'
    },
  },

  watch: {
    searchValue() {
      if (!this.searchValue) this.selectedSpecialty = ''
      // ensure specialties are shown when the user types
      if (!this.setSpecialtyFocused) this.setSpecialtyFocused = true
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent', 'sendViewEvent']),
    ...mapActions('identity', ['updateSpecialty']),
    ...mapActions('jobs', ['getJobRecommendations']),
    ...mapActions('account', ['getUserDetails']),

    async saveSpecialty() {
      this.isSaving = true
      await this.updateSpecialty(this.selectedSpecialty.key)
      await this.getUserDetails()
      await this.getJobRecommendations()
      this.isSaving = false
    },

    selectSpecialty(specialty) {
      if (!specialty) return
      this.selectedSpecialty = specialty
      this.searchValue = specialty.name
      this.setSpecialtyFocused = false
    },

    handleBlur() {
      setTimeout(() => {
        this.setSpecialtyFocused = false
      }, 250)
    },

    handleNewJobsAccordionClick({ isOpen }) {
      const action = isOpen ? 'Expand' : 'Collapse'

      this.sendClickEvent({
        name: `New Jobs ${action} Section`,
        text: `${action} New Jobs`,
        area: 'action-pane/new-jobs',
      })
    },
  },
}
