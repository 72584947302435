
import { mapActions, mapGetters, mapState } from 'vuex'
import TravelCard from './TravelCard/index.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'

export default {
  name: 'Travel',
  components: {
    PDEHeading,
    PDESkeleton,
    TravelCard,
  },
  computed: {
    ...mapGetters('assignment', ['allAssignmentDetailsRequestsArePending', 'getSelectedShiftAssignmentId']),
    ...mapGetters('travel', ['errorGetter', 'isLoadingGetter']),
    ...mapState('travel', ['itineraries']),
    getItineraries() { return this.itineraries.filter((it) => it.assignmentId === this.getSelectedShiftAssignmentId && it.portalStatus !== 'Pending') },
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    assignmentTravelClicked(travelTitle) {
      this.sendClickEvent({
        name: 'Assignment Travel Itinerary Click',
        text: `travel itinerary: ${travelTitle}`,
        area: 'action-pane/assignment',
      })
    },
  },
}
