
import { mapGetters } from 'vuex'
import MyTeamCards from '@/components/account/Dashboard/Assignments/Cards/MyTeamCards/index.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'

export default {
  name: 'MyTeam',

  components: {
    MyTeamCards,
    PDEHeading,
    PDESkeleton,
  },

  props: {
    assignmentId: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('assignment', ['getMyTeamDetailsForJobByAssignmentId', 'allAssignmentDetailsRequestsArePending']),
    ...mapGetters('account', ['providerRep']),
  },
}
